.App {
  text-align: center;
}
body {
  background-color: #223850;
  overflow-x: hidden;
  /* Hide scrollbars */
}
.LogoSplashScreen {
  position: fixed;
  display: block;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 85%;
  max-height: 85%;
  animation: fade 4s ease-in;
  animation-fill-mode: forwards;
}

.LogoSplashScreenHome {
  position: fixed;
  display: block;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 85%;
  max-height: 85%;
  animation: fadeOut 2s ease-in;
  animation-fill-mode: forwards;
}

.WhiteScreen {
  pointer-events: none;
  height: 100%;
  width: 100%;
  animation: fadeOut 2s ease-in;
  background-color: #a8d2c8;
  position: fixed;
  z-index: 20;
  top: 0;
  animation-fill-mode: forwards;
}
.SplashScreenDiv {
  background-color: #a8d2c8;
  height: 100%;
  width: 100%;
  position: absolute;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
  0% {
    z-index: 20;
  }
  100% {
    z-index: 0;
  }
}
@keyframes fade {
  0%,
  90%,
  100% {
    opacity: 0;
  }
  40%,
  60% {
    opacity: 1;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body::-webkit-scrollbar {
  width: 0.6rem;
}
body::-webkit-scrollbar-track {
  background: rgb(54, 54, 54);
}
body::-webkit-scrollbar-thumb {
  background: rgb(194, 193, 193);
  border-radius: 100px;
}

:root {
  --magic-number: 1.11858047211;
  --content-width: 100vw;
  --skew-padding: calc(var(--content-width) * var(--magic-number));
  height: 100%;
  width: 100%;
  font-size: 16px;
  font-family: "Open sans";
}
.image-gallery {
  width: 100%;
  height: auto;
}
.image-gallery-slide img {
  width: 85%;
  height: auto;
}
.image-gallery-thumbnail {
  margin-top: 80vh;
  margin-left: 10%;
}

.navbarImg {
  position: fixed;
  width: 4rem;
  height: 4rem;
}
.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  position: fixed;
}
.blackBoard {
  margin-top: 3%;
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  max-width: 85%;
  max-height: 85%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blackBoardPhoneOne {
  z-index: 1;
  margin-left: 7.5%;
  position: relative;
  display: block;
  width: auto;
  height: auto;
  max-width: 85%;
  max-height: 85%;
  transform: translate(-0%, 25%);
}
.blackBoardPhoneTwo {
  z-index: 1;
  margin-top: 24%;
  margin-left: 7.5%;
  position: relative;
  display: block;
  width: auto;
  height: auto;
  max-width: 85%;
  max-height: 85%;
}
.blackBoardPhoneThree {
  z-index: 1;
  margin-top: 3%;
  margin-left: 7.5%;
  position: relative;
  display: block;
  width: auto;
  height: auto;
  max-width: 85%;
  max-height: 85%;
}
.backBoardPhoneOne {
  position: fixed;
  width: 100%;
  height: 150vh;
}
.backBoardPhoneTwo {
  position: fixed;
  width: 100%;
  height: 100vh;
}
.backBoardPhoneThree {
  position: fixed;
  width: 100%;
  height: 100vh;
}
.backBoard {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.TextboxBackboard {
  object-fit: cover;
  position: fixed;
  border-radius: 20px;
  float: left;
  width: 100%;
  height: auto;
  top: 50%;
  transform: translate(-0%, -50%);
  max-width: 40%;
  margin-top: 2.5%;
}
.TextboxBlackboard {
  position: fixed;
  display: block;
  float: left;
  width: auto;
  height: auto;
  top: 50%;
  transform: translate(-0%, -50%);
  max-width: 35%;
  margin-top: 2.5%;
  margin-left: 2.5%;
}
.TextboxBlackboardHistory {
  position: absolute;
  display: block;
  float: left;
  width: 87.5%;
  height: 110%;
  top: 45%;
  transform: translate(-0%, -50%);
  margin-top: 2.5%;
  margin-left: 10%;
}
.TextboxBackboardHistory {
  border-radius: 20px;
  position: absolute;
  display: block;
  float: left;
  width: 96%;
  height: 120%;
  top: 45%;
  transform: translate(-0%, -50%);
  margin-top: 2.5%;
  margin-left: 7%;
  z-index: 0;
}

.spacer {
  aspect-ratio: 540/960;
  width: 100%;
  right: 0;
  background-repeat: repeat;
  background-size: cover;
  z-index: 1;
  position: relative;
}
.layer1 {
  background-image: url(./wave-haikei-two.svg);
}
.historyText {
  margin-top: 10%;
  right: 0;
  margin-left: 80%;
  position: absolute;
  z-index: 10;
  text-align: left;
}
.spacer2 {
  aspect-ratio: 540/960;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  position: fixed;
}
.layer2 {
  background-image: url(./wave-haikei-mobile.svg);
}

.Navbar {
  position: fixed;
  z-index: 4;
  background-color: #a8d2c8;
  width: 101%;
  border-color: #a8d2c8;
  height: 4.1rem;
  box-shadow: black 0px 0.2rem 0.4rem 0.5rem;
}
.navLogo {
  margin-left: 10px;
}
.NavbarButtons {
  position: sticky;
  align-content: center;
  background-color: #a8d2c8;
  cursor: pointer;
  display: inline-block;
  margin-right: 10%;
}
.link {
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  display: flex;
}
.link:visited {
  text-decoration: none;
  color: #000;
}
.link:hover {
  text-decoration: none;
}
.link:focus {
  text-decoration: none;
}
.link:hover,
.link:active {
  text-decoration: none;
}

li {
  display: inline-block;
}
.imgLeft {
  object-fit: cover;
  position: fixed;
  display: block;
  border-radius: 20px;
  background: white;
  float: left;
  margin-left: 7%;
  width: 100%;
  height: auto;
  top: 50%;
  transform: translate(-0%, -50%);
  max-width: 40%;
  margin-top: 2.5%;
}
.textboxLeft {
  border-radius: 20px;
  display: block;
  float: left;
  width: 47.5%;
  padding-bottom: 47.5%;
  top: 50%;
  margin-left: 7%;
  height: 0;
  max-width: 40%;
  max-height: 47.5%;
  margin-top: 15%;
}
.textboxLeftBottom {
  border-radius: 20px;
  display: block;
  float: left;
  width: 47.5%;
  margin-top: 15%;
  margin-left: 7%;
  padding-bottom: 47.5%;
  top: 50%;
  height: 0;
  max-width: 40%;
  max-height: 47.5%;
}
.imgRightBottom {
  object-fit: cover;
  position: fixed;
  display: block;
  border-radius: 20px;
  background: white;
  float: left;
  margin-right: 7%;
  width: 100%;
  height: auto;
  top: 50%;
  transform: translate(-0%, -50%);
  max-width: 40%;
  margin-top: 2.5%;
}
.textboxRightBottom {
  border-radius: 20px;
  display: block;
  float: right;
  width: 47.5%;
  margin-top: 15%;
  margin-right: 7%;
  padding-bottom: 47.5%;
  top: 50%;
  height: 0;
  max-width: 40%;
  max-height: 47.5%;
}
.imgLeftBottom {
  object-fit: cover;
  position: fixed;
  display: block;
  border-radius: 20px;
  background: white;
  float: left;
  width: 100%;
  margin-left: 7%;
  height: auto;
  top: 50%;
  transform: translate(-0%, -50%);
  max-width: 40%;
  margin-top: 2.5%;
}
.textboxRight {
  border-radius: 20px;
  display: block;
  float: right;
  width: 47.5%;
  margin-right: 7%;
  padding-bottom: 47.5%;
  top: 50%;
  height: 0;
  max-width: 40%;
  max-height: 47.5%;
  margin-top: 15%;
}
.imgRight {
  object-fit: cover;
  position: fixed;
  display: block;
  border-radius: 20px;
  background: white;
  float: right;
  margin-right: 7%;
  width: 100%;
  height: auto;
  top: 50%;
  transform: translate(-0%, -50%);
  max-width: 40%;
  margin-top: 2.5%;
}
.imgMiddle {
  position: absolute;
  display: block;
  margin-top: 15%;
  border-radius: 20px;
  background: white;
  float: right;
  width: auto;
  height: auto;
  max-width: 33%;
  z-index: 4;
}
.backboardMiddle {
  position: absolute;
  display: block;
  margin-top: 7%;
  border-radius: 20px;
  background: white;
  float: right;
  width: auto;
  height: auto;
  max-width: 40%;
  z-index: 4;
}
.blackboardText {
  font-family: chalkboard;
  color: #e0e0e0;
  font-size: 11px;
  font-size: 1.9vw;
  margin: 7%;
  position: relative;
  z-index: 10;
  text-align: center;
  width: 86%;
}
.blackboardTextTitle {
  font-family: chalkboard;
  text-align: center;
  color: #e0e0e0;
  font-size: 11px;
  font-size: 1.9vw;
  margin: 7%;
  position: relative;
  z-index: 10;
  width: 86%;
}
.ItemHead {
  z-index: 10;
  grid-area: header;
}
.itemMain {
  grid-area: main;
}

.grid {
  display: grid;
  border-radius: 20px;
  grid-template-areas: p "header header header" "main main main" "main main main";
  grid-gap: 1rem;
  background: white;
}
.HistoryImage {
  margin-top: 5%;
  width: 100%;
  height: auto;
  max-width: 100%;
}
.mainGrid {
  margin: 10px;
  border-radius: 20px;
  display: grid;
  gap: 1rem;
  background: #223850;
}

.grid > div {
  background: white;
  text-align: center;
}
.HistoryImgWrapper {
  height: 100px;
  width: 100px;
  overflow: hidden;
  object-fit: cover;
  object-position: 20% 10%; /* try 20px 10px */
}
section {
  background: #a8d2c8;
  position: relative;
  color: white;
  clip-path: polygon(0 44%, 100% 0%, 100% 100%, 0% 100%);
  margin-top: -28%;
  padding: 30% 10%;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  z-index: 0;
}
.sectionTwo {
  background: #223850;
  position: absolute;
  z-index: 0;
  color: white;
  clip-path: polygon(0 44%, 100% 0%, 100% 100%, 0% 100%);
  margin-top: -32%;
  padding: 30% 10%;
  align-items: center;
  overflow: hidden;
  width: 80%;
  max-width: 100%;
  overflow-x: hidden;
}
.sectionThree {
  background: gainsboro;
  position: absolute;
  z-index: 0;
  clip-path: polygon(0 45%, 100% 0%, 100% 100%, 0% 100%);
  margin-top: -30.5%;
  padding: 30% 10%;
  align-items: center;
  overflow: hidden;
  width: 80%;
  max-width: 100%;

  overflow-x: hidden;
}
.HistoryCont {
  background: #a8d2c8;
  width: 100%;
  z-index: 0;
  margin-top: -28%;
  position: relative;
  align-items: center;
  height: auto;
}
.filler {
  height: 15%;
  width: 100%;
  background: #a8d2c8;
  position: absolute;
  z-index: -1;
}
.HistoryTextbox {
  margin-left: 7%;
  margin-right: 7%;
}
.HistoryText {
  font-family: chalkboard;
  color: #e0e0e0;
  font-size: 11px;
  font-size: 1.9vw;
  margin-left: 10%;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 86%;
}
.HistoryTextPhone {
  font-family: chalkboard;
  color: #e0e0e0;
  font-size: 20px;
  font-size: 4vw;
  margin-left: 4%;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 86%;
}

.Footer {
  position: absolute;
  z-index: 4;
  background-color: #a8d2c8;
  width: 101%;
  border-color: #a8d2c8;
  height: 5.1rem;
  box-shadow: black 0px 0.2rem 0.4rem 0.5rem;
  bottom: -10.1rem;
}
.Footer-Menu {
  position: absolute;
  z-index: 4;
  background-color: #a8d2c8;
  width: 101%;
  border-color: #a8d2c8;
  height: 5.1rem;
  box-shadow: black 0px 0.2rem 0.4rem 0.5rem;
  bottom: -5.1rem;
}
.Footer-History {
  position: relative;
  z-index: 4;
  background-color: #a8d2c8;
  width: 101%;
  border-color: #a8d2c8;
  height: 5.1rem;
  box-shadow: black 0px 0.2rem 0.4rem 0.5rem;
  bottom: -5.1rem;
}
.Footer-Home {
  position: absolute;
  z-index: 20;
  background-color: #a8d2c8;
  width: 101%;
  border-color: #a8d2c8;
  height: 5.1rem;
  box-shadow: black 0px 0.2rem 0.4rem 0.5rem;
  bottom: 0rem;
}

.Footer-PrivacyPage {
  position: relative;
  z-index: 20;
  background-color: #a8d2c8;
  width: 101%;
  border-color: #a8d2c8;
  height: 5.1rem;
  box-shadow: black 0px 0.2rem 0.4rem 0.5rem;
  bottom: 0rem;
}
.Policy {
  margin-left: 7%;
  margin-right: 7%;
  background-color: white;
}

.facebook {
  position: absolute;
  z-index: 100;
  left: 0%;
  top: 0%;
  margin-left: 1%;
  color: #0000;
}

.facebook:hover {
  fill: white;
}

.privacyLink {
  position: absolute;
  bottom: 10%;
  left: 2%;
  color: black;
}
.privacyLink:link {
  text-decoration: none;
}
.privacyLink:hover {
  text-decoration: underline;
}

.Copyright {
  position: absolute;
  color: white;
  bottom: 10%;
  right: 0;
  margin-right: 7%;
}
.PriBackgroud {
  background-color: white;
  margin: 10%;
}

@media only screen and (min-width: 600px) {
  .NavbarButtons {
    margin-left: 15vw;
    width: 25vw;
    margin-bottom: 4x;
  }
  .link {
    margin-left: 13vw;
    width: 25vw;
    margin-bottom: 4x;
  }
  .imgLeft {
    max-width: 40%;
  }
  .textboxLeft {
    max-width: 40%;
  }
  .textboxLeftBottom {
    max-width: 40%;
  }
  .textboxRightBottom {
    max-width: 40%;
  }
  .imgRightBottom {
    max-width: 40%;
  }
  .textboxRight {
    max-width: 40%;
  }
  .imgRight {
    max-width: 40%;
  }
}
@media only screen and (min-width: 2000px) {
  .TextboxBackboardHistory {
    top: 35%;
  }
  .TextboxBlackboardHistory {
    top: 35%;
  }
  .filler {
    height: 0%;
  }
  .HistoryImage {
    margin-top: 1%;
  }
}
@media only screen and (max-width: 600px) {
  .privacyLink {
    bottom: 50%;
    left: 45%;
  }
  .Copyright {
    left: 25%;
    bottom: 20%;
  }

  .Footer-Menu {
    position: relative;
    bottom: 0rem;
  }
  .HistoryText {
    font-size: 4vw;
  }

  .HistoryTextbox {
    margin-left: 7%;
    margin-top: 20%;
    margin-right: 7%;
  }
  .spacer {
    overflow-x: hidden;
  }
  .layer1 {
    aspect-ratio: 9/20;
    right: -30%;
    overflow-x: hidden;
    max-height: 100%;
  }
  .HistoryImage {
    height: 100%; /* height of container */
    overflow: hidden;
  }

  .imgMiddle {
    max-width: 70%;
  }
  .backboardMiddle {
    max-width: 80%;
  }
  .TextboxBackboard {
    max-width: 97%;
    margin-left: -0.5%;
  }
  .TextboxBlackboard {
    max-width: 90%;
    margin-left: 3%;
  }

  .blackboardText {
    margin-bottom: 1%;
    font-size: 4vw;
    top: -10%;
  }
  .blackboardTextTitle {
    margin-top: 3%;
    font-size: 4vw;
  }
  .NavbarButtons {
    margin-left: 20vw;
    width: 10vw;
  }
  .link {
    margin-left: 20vw;
    width: 10vw;
  }
  .black {
    display: block;
    max-width: 98%;
    max-height: 98%;
    width: auto;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .imgLeft {
    width: 97%;
    height: auto;
    max-width: 97%;
    margin: 1.5%;
  }
  .imgRight {
    width: 97%;
    height: auto;
    max-width: 97%;
    margin-left: 1.5%;
  }

  .imgLeftBottom {
    width: 97%;
    height: auto;
    max-width: 97%;
    margin: 1.5%;
  }
  .imgRightBottom {
    width: 97%;
    height: auto;
    max-width: 97%;
    margin-left: 1.5%;
  }
  .textboxLeft {
    width: 97%;
    margin: 1.5%;
    height: 25%;
    max-width: 97%;
  }
  .textboxLeftBottom {
    width: 97%;
    margin: 1.5%;
    height: 25%;
    max-width: 97%;
  }
  .textboxRightBottom {
    width: 97%;
    margin: 1.5%;
    height: 25%;
    max-width: 97%;
  }
  .textboxRight {
    width: 97%;
    margin: 1.5%;
    height: 25%;
    max-width: 97%;
  }
}
@media only screen and (max-width: 475px) {
  .blackboardText {
    margin-bottom: 1%;
    font-size: 4.3vw;
    top: -10%;
  }
  .blackboardTextTitle {
    margin-top: 10%;
    font-size: 4.2vw;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
